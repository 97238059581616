import Home from "./component/Home";
import {BrowserRouter , Route, Routes} from "react-router-dom";


function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" Component={Home}></Route>   
        </Routes>       
      </BrowserRouter>  
    </>
    
  );
}

export default App;
